import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { Routes, Route, useNavigate } from "react-router-dom";
import "../tabstyle.css";
import Dropdown from "react-bootstrap/Dropdown";
import { SocialIcon } from "react-social-icons";
// import RequestContent from "../RequestContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch} from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer";

const TabComponent = () => {
  const [val, setVal] = useState("one");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showRequest, setShowRequest] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleTab = (e, newVal) => {
    setVal(newVal);
  };

  const handleShow = (productId) => {
    navigate(`/home/${productId}`);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://apicdn.storyyell.in/"
      );
      const jsonData = await response.json();
      setData(jsonData.images);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  

  const handleRequestClick = () => {
    setShowRequest(false);
  };

  const [query, setQuery] = useState("");

  const search_parameters = Object.keys(Object.assign({}, ...data));

  function search(data) {
    return data.filter((data) =>
      search_parameters.some((parameter) =>
        data[parameter].toString().toLowerCase().includes(query)
      )
    );
  }

  const renderProducts = () => {
    if (val === "one") {
      return (
        <>
          <Container className="pb-5">
            <Row className="product-row">
              {search(data).map((product, index) => (
                <Col className="product-images col-md-3" key={index}>
                  <img
                    src={product.imageSrc}
                    alt={product.name}
                    onClick={() => handleShow(product.id)}
                  />
                  <img
                    className="product-subimage"
                    src={product.logo}
                    alt={product.name}
                  />
                  <p id="product-name">{product.name}</p>
                  <p id="product-subname">{product.subname}</p>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <center>
        <Container>
          <Row>
            <header className="App-header">
              <div>
                <h1 className="home-heading">
                  On<span>broda</span>
                </h1>
              </div>
            </header>
          </Row>
          
          <Row>
            <div className="search ....">
              <div className="search-container">
                <div className="search-input-container">
                  <FontAwesomeIcon icon={faSearch} className="search-icon" />
                  <input
                    type="text"
                    placeholder="Search Onbordings..."
                    onChange={(e) => setQuery(e.target.value)}
                    className="search-input" // Add a class for styling
                  />
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div
              style={{
                color: "#e5dada",
                lineHeight: "0.5",
                marginTop: "22px",
                fontSize: "12px",
              }}
            >
              <p>Discover the ease of onboarding for user satisfaction with a </p>
              <p>seamless experience that makes a lasting impression!</p>
            </div>
          </Row>
        </Container>
        <div
          style={{
            paddingBottom: "1px",
            color: "#EAAB4E",
            marginTop: "16px",
            fontSize: "12px",
            fontWeight: "bold",
            borderBottom: "1px solid #2B3139",
          }}
        >
          <p>100+ ONBOARDING SCREENS</p>
        </div>
        <Container>
          <div>{renderProducts()}</div>
        </Container>
        <Footer />
      </center>
    </>
  );
};

export default TabComponent;
